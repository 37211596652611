<script>
import { defineComponent, nextTick, ref, watch, getCurrentInstance } from 'vue'
import { useUnits } from '../useUnits'
import { ValidationObserver } from 'vee-validate'
import FormItem from '@/components/Input/FormItem.vue'

export default defineComponent({
  components: {
    VNode: {
        functional: true,
        render: (h, ctx) => ctx.props.vnodes,
    },
    ValidationObserver,
    FormItem,
  },
  props: {
    value: {
      required: true,
    },
    excludes: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    availableUnit: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { $message } = getCurrentInstance().proxy.$root
    const { add: addUnit, remove: removeUnit, filteredUnitAsOptions } = useUnits()
    const innerValue = ref(props.value)
    watch(() => props.value, () => innerValue.value = props.value)
    watch(innerValue, () => {
      emit('input', innerValue.value)
      emit('change', innerValue.value)
      emit('select', innerValue.value)
      emit('update', innerValue.value)
    })

    const addForm = ref(null)
    const addMode = ref(false)
    const addUnitText = ref('')
    const addNewUnit = async (e) => {
      e.preventDefault()
      if(!await addForm.value.validate()) return

      const value = addUnitText.value.toUpperCase()

      // props.availableUnit.map(v => v.unit.toUpperCase()).includes(value)
      if (props.availableUnit[0]?.unit.toUpperCase() === value) {
        $message.destroy()
        $message.error('Nama unit tidak boleh sama dengan satuan dasar')
      } else {
        addUnit(value)
        innerValue.value = value
        addUnitText.value = ''
        addMode.value = false
      }
    }

    watch(addMode, (v) => {
      if (v) nextTick(() => {
        addForm.value.$el.querySelector('input').focus()
      })
    })

    return {
      addUnit,
      removeUnit,
      filteredUnitAsOptions,
      innerValue,
      addForm,
      addMode,
      addUnitText,
      addNewUnit,
    }
  },
})
</script>

<template>
  <div>
    <a-select 
      v-model="innerValue"
      dropdown-class-name="unit-selector--dropdown"
      class="unit-selector w-100"
      :disabled="disabled"
    >
      <a-select-option v-for="(unit) in filteredUnitAsOptions($props.excludes || [])" :key="unit.value" :value="unit.value">
        {{ unit.label }}
      </a-select-option>
      <template #dropdownRender="menu">
        <VNode :vnodes="menu" />
        <a-divider class="unit-selector--divider" />
        <div class="unit-selector--addon">
          <button
            @click="addMode = true" 
            @mousedown="e => e.preventDefault()"
          >
            {{ $t('product.add_new_unit') }}
          </button>
        </div>
      </template>
    </a-select>

    <a-modal v-model="addMode" class="unit-selector--add-modal" :footer="false" :closable="false">
      <ValidationObserver v-if="addMode" ref="addForm" tag="form" @submit="addNewUnit">
        <FormItem name="Unit" rules="required|min:2|max:15" tag="div" class="form-item-no-margin">
          <a-input
            v-model="addUnitText"
            :max-length="15"
            placeholder="Unit name"
            class="h-48px"
          >
            <template #addonAfter>
              <a-button
                type="primary"
                html-type="submit"
                class="px-5"
                style="margin: 0 -11px !important; height: 46px; border-radius: 0 2px 2px 0"
              >
                {{ $t('utils.add') }}
              </a-button>
            </template>
          </a-input>
        </FormItem>
      </ValidationObserver>
    </a-modal>
  </div>
</template>

<style lang="scss">
.h-48px {
  > .unit-selector .ant-select-selection--single {
    height: 48px;
  }
}

.unit-selector {
  width: 100%;

  &.ant-select-disabled .ant-select-selection {
    background-color: #fff;
    color: var(--dark-gray);
  }

  &--divider {
    margin: 4px 0 !important;
  }

  &--addon {
    padding: 0 4px 4px;
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;

    button {
      width: 100%;
      position: relative;
      background-color: var(--kit-color-primary);
      color: #fff;
      border: 0;
      line-height: 1.25;
      padding: 8px;
      border-radius: .2rem;
      border: 1px solid var(--kit-color-primary);
      transition: all .1s ease-in;

      &:hover {
        background-color: #fff;
        color: var(--kit-color-primary);
      }
    }

    input { 
      width: 100%;
    }
  }

  &--add-modal {
    input {
      text-transform: uppercase;
    }
  }
}
</style>
